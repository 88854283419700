
export default {
  data() {
    return {
      showMaxOptions: 5,
      isOpen: false,
      submitted: false,
      isOptionsCollapsed: true,
      filtersChanged: false,
      selectedTotalPrice: 0,
      rejectedOptions: []
    }
  },
  computed: {
    results () {
      const results = this.$store.state.searchResults
      if (!results.length) return []
      return results
    },
    searchFilters () {
      return this.$store.state.searchCarsCheckboxes
    },
    filters () {
      const totalPrice = this.results.map(result => result.minPrice)

      const minTotalPrice = Math.min(...totalPrice)
      const maxTotalPrice = Math.max(...totalPrice)

      return {
        minTotalPrice,
        maxTotalPrice
      }
    },
    allowFilter () {
      return (
        this.selectedTotalPrice !== this.filters.maxTotalPrice ||
        this.filtersChanged
      )
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: 'selectDefaults'
    }
  },
  methods: {
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    submit () {
      if (!this.allowFilter) return

      this.submitted = true

      setTimeout(() => {
        this.scrollTo('car-results')
      }, 500)

      this.$store.commit('setSearchCarsFilters', {
        rejectedOptions: this.rejectedOptions,
        totalPrice: this.selectedTotalPrice
      })
    },
    selectDefaults () {
      this.selectedTotalPrice = this.filters.maxTotalPrice
      this.rejectedOptions = this.rejectedOptions.slice()
    },
    clearFilters () {
      this.submitted = false
      this.$store.commit('clearSearchCarsFilters')
      this.selectDefaults()
      Object.keys(this.searchFilters).forEach((filterKey) => {
        this.optionsSelectAll(filterKey)
      })
    },
    toggleOption (category, e) {
      const code = e.target.value
      if (e.target.checked) {
        const index = this.rejectedOptions.indexOf(code)
        if (index !== -1) this.rejectedOptions.splice(index, 1)
      } else {
        // deconstructing the array to avoid mutation
        this.rejectedOptions = [...this.rejectedOptions, code]
      }
      const checked = e.target.checked
      this.setSearchOptionsChecked(category, code, checked)
      this.filtersChanged = true
    },
    setSearchOptionsChecked (category, code, checked) {
      this.filtersChanged = true
      this.$store.commit('setSearchOptionsChecked', {
        category,
        code,
        checked
      })
    },
    optionsSelectAll (filterKey) {
      this.rejectedOptions = []
      const keys = Object.keys(this.searchFilters[filterKey].options)

      keys.forEach((option) => {
        this.setSearchOptionsChecked(filterKey, option, true)
      })

      const checkboxes = keys.map(key => this.$refs[key])

      if (Array.isArray(checkboxes)) {
        checkboxes.forEach(checkbox => {
          checkbox[0].checked = true
        })
      } else {
        checkboxes.checked = true
      }
    },
    optionsClearAll (filterKey) {
      const keys = Object.keys(this.searchFilters[filterKey].options)
      this.rejectedOptions = keys

      keys.forEach((option) => {
        this.setSearchOptionsChecked(filterKey, option, false)
      })
      this.$store.commit('setClearAlloptionsEnabled', true)

      const checkboxes = keys.map(key => this.$refs[key])

      if (Array.isArray(checkboxes)) {
        checkboxes.forEach(checkbox => {
          checkbox[0].checked = false
        })
      } else {
        checkboxes.checked = false
      }
    }
  }
}
