export default ({ store }, inject) => {
  return

  // Just for farescraper for now
  if (store.state.siteBrand !== 'farescraper') return

  if (process.client) {
    window.ezstandalone = window.ezstandalone || {}
    window.ezstandalone.cmd = window.ezstandalone.cmd || []
    window.ezstandalone.cmd.push(function () {
      window.ezstandalone.define(store.state.siteConfig.ezoic)
      window.ezstandalone.enable()
      window.ezstandalone.display()
    })
  }
}