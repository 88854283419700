import { format } from 'date-fns'
import { v4 as uuidv4 } from 'uuid'
import Axios from 'axios'
import insertRingbaScript from '@/helpers/insertRingbaScript'

export default async function ({ app, req, query, store, $axios, $config }) {
  if (store.state.entryId) {
    console.info('Entry ID: skip')
    return
  }

  const entryId = query.entryId ?? uuidv4()
  store.commit('setEntryId', entryId)
  app.$cookies.set('entryId', entryId, {
    path: '/',
    maxAge: 60 * 60 * 24 * 7,
    sameSite: 'strict'
  })

  const debug = query.debug ?? false
  store.commit('setDebug', debug)

  const previousEntryId = app.$cookies.get('entryId')
  const ostype = process.client ? window.navigator.userAgent : req.headers['user-agent']

  store.commit('setOstype', ostype)  

  console.info('Getting entry ID')
  const { country, ip, city, site, deviceType, browser } = store.state
  const { deviceCategory } = store.getters

  const payload = {
    deeplinks: 1,
    entryid: entryId,
    results: query.results ?? 0,
    siteaddress: site.replace('v2.', '').replace('new.', ''),
    originalentryid: previousEntryId,
    keyword: query.utm_term,
    devicecategory: deviceCategory,
    devicetype: deviceType,
    browserversion: browser.version,
    userlocaldate: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
    userlocaltime: format(new Date(), 'HH:mm:ss'),
    adid: query.msclkid || query.gclid,
    sitebrand: 'flights',
    trafficsource: query.utm_source,
    browsertype: browser.name,
    ostype: ostype,
    country: query.country ?? country,
    urlquery: JSON.stringify(query)
  }

  // Get p parameters
  let pParameter = []
  Object.entries(query).forEach(([key, value]) => {
    if (key.startsWith('p[') && key.endsWith(']')) {
      const index = parseInt(key.replace('p[', '').replace(']', ''))
      pParameter = pParameter || []
      pParameter[index] = value
    }
  })

  // If visitor goes straight into results, force p[0]=T
  const urlPathname = window.location.pathname
  if ((urlPathname === '/car-results') || (urlPathname === '/results') || (payload.results == 1)) {
    console.log('Forcing p[0]=T')
    pParameter[0] = 'T'
  }

  if (pParameter.length) {
    payload.p = pParameter
  }

  payload.cookies = app.$cookies.getAll();

  if (query.entryId) {
    console.log(`Used Entry ID from query parameter: ${entryId}`)
  } else {
    console.log(`Autogenerated Entry ID: ${entryId}`)
  }

  const baseURL = $config.API_URL
  const axios = Axios.create()

  axios
    .post(baseURL + '/visitor', payload)
    .then((response) => {
      response = response.data
      console.log('Visitor response: ', response)

      if (response.deeplinks) store.commit('setDeeplinks', response.deeplinks)
      if (response.country) store.commit('setCountry', response.country)
      if (response.city) store.commit('setCity', response.city)
      if (response.query) store.commit('setIp', response.query)
      store.commit('setIsGeolocated', true)
      store.commit('setIsIdentified', true)
      store.commit('setAdsRequestParams', response);

      const siteConfig = store.state.siteConfig
      insertRingbaScript(siteConfig)
    })

  console.log(JSON.stringify(payload, true))
}
