
import get from 'lodash/get'
import getHead from '@/helpers/getHead'
import loadScript from '@/helpers/loadScript'

export default {
  head () {
    const { site, siteBrand, siteName, siteType, siteConfig, language, rtl } = this.$store.state
    const thumbnail = `/images/${siteType}/thumbnail.png`
    const blockPushnami = get(this.$route.query, 'utm_source', '').startsWith('prpl_') || (['loading', 'static', 'privacy', 'terms', 'about', 'contact'].includes(this.$route.name))
    // if (blockPushnami) console.info('Avoid loading Pushnami')

    let headLink = [
      { rel: 'icon', type: 'image/x-icon', href: `/images/favicons/${site}.ico` }
    ];

    // Add cannonical for /blog and /flights
    if (this.$route.fullPath.includes('blog') || this.$route.fullPath.includes('flights')) {
      headLink.push({ rel: 'canonical', href: `https://farescraper.com${this.$route.fullPath}` })
    }

    return getHead({
      root: site,
      title: siteName,
      language,
      rtl,
      description: this.$t(`${siteType}.meta.description`),
      image: thumbnail,
      link: headLink,
      style: [
        { cssText: `:root { --primary: ${siteConfig.color}; }`, type: 'text/css' }
      ],
      script: [
        // this.$config.IS_PRODUCTION && !blockPushnami && siteConfig.jeeng ? { defer: true, src: `https://${siteConfig.jeengDomain}/users/domains/${siteConfig.jeeng}/sdk/` } : {},
        { defer: true, src: `https://travel${!this.$config.IS_PRODUCTION ? '-test' : ''}.mediaalpha.com/js/serve.js` },
        { async: true, src: 'https://js.adara.com/index.js' },
        // siteBrand === 'farescraper' ? { async: true, src: 'https://www.ezojs.com/ezoic/sa.min.js' } : {},
        { async: true, src: `https://dynamic.criteo.com/js/ld/ld.js?a=${this.$config.CRITEO_ACCOUNT}` }
      ]
    })
  },
  mounted () {
    const brand = this.$store.state.siteBrand
    this.$store.dispatch('phones/index', brand)
    this.loadPushnami()
  },
  methods: {
    async startServiceWorker () {
      if ('serviceWorker' in navigator) {
        try {
          console.log('Register SW...')
          let registration

          setTimeout(() => {
            console.log({ registration })
          }, 3000)

          registration = await navigator.serviceWorker.register('/firebase-messaging-sw.js', {
            scope: '/',
          });

          if (registration.installing) {
            console.log('Service worker installing');
          } else if (registration.waiting) {
            console.log('Service worker installed');
          } else if (registration.active) {
            console.log('Service worker active');

            // this.loadJeeng()
            this.loadPushnami()
          }
        } catch (error) {
          console.error(`Registration failed with ${error}`);
        }
      }
    },
    loadPushnami () {
      const { siteConfig } = this.$store.state
      const blockPushnami = get(this.$route.query, 'utm_source', '').startsWith('prpl_') || (['loading', 'static', 'privacy', 'terms', 'about', 'contact'].includes(this.$route.name))

      // if (blockPushnami || !this.$config.IS_PRODUCTION && !siteConfig.pushnami) {
      if (blockPushnami || !siteConfig.pushnami) {
        console.info('Avoid loading Pushnami')
        return
      }

      const script = document.createElement('script')
      script.src = `https://api.pushnami.com/scripts/v1/pushnami-adv/${siteConfig.pushnami}`
      script.defer = true
      script.onload = () => { 
        console.log('Pushnami loaded!') 
        Pushnami.update().prompt()
      }
      document.head.appendChild(script)
    },
    loadJeeng () {
      const { siteConfig } = this.$store.state
      const blockPushnami = get(this.$route.query, 'utm_source', '').startsWith('prpl_') || (['loading', 'static', 'privacy', 'terms', 'about', 'contact'].includes(this.$route.name))

      if (blockPushnami || !this.$config.IS_PRODUCTION && !siteConfig.jeeng) {
        console.info('Avoid loading Jeeng')
        return
      }

      const script = document.createElement('script')
      script.src = `https://${siteConfig.jeengDomain}/users/domains/${siteConfig.jeeng}/sdk/`
      script.defer = true
      script.onload = () => { console.log('Jeeng loaded!') }
      document.head.appendChild(script)
    }
  }
}
