
import vClickOutside from 'v-click-outside'
import analytics from '@/helpers/analytics'

const providers = ['ladybug', 'emirates', 'priceline', 'smartfares']

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    flight: {
      type: Object,
      default: null,
      required: false
    }
  },
  data () {
    return {
      providers,
      showAllProviders: false,
      maxCompareProviders: 3
    }
  },
  computed: {
    hasFlight () {
      return this.flight !== null
    },
    flightPartners () {
      return this.flight ? this.flight.partners : []
    },
    parsedItems () {
      return [...this.flightPartners, ...this.items].map((item, index) => ({
        ...item,
        visible: this.showAllProviders || (index < this.maxCompareProviders),
        order: index < 3 ? 1 : 3
      }))
    },
    showClickToCall () {
      return false
    },
    toggleShowMoreText () {
      return this.showAllProviders ? 'search.lessProviders' : 'search.moreProviders'
    },
    shouldShowShowMore () {
      return this.items.length > this.maxCompareProviders
    },
    site () {
      return this.$store.state.site
    },
    siteType () {
      return this.$store.state.siteType
    }
  },
  methods: {
    toggleShowAll () {
      this.showAllProviders = !this.showAllProviders
    },
    compareAll () {
      const eventName = (this.flight === null) ? 'compare-all-click' : 'compare-all-click-flight'
      analytics.track(eventName, this)
      this.$emit('compareAll', this.flight)
    },
    compare (index) {
      ++index
      analytics.track(`compare-${index}-click`, this)
    },
    partnerClick() {
      // Conversion
      if (this.flight !== null) {

        // Google conversion
        if (this.$gtag && this.$store.state.siteConfig.googleConversion) {
          this.$gtag.event('conversion', {
            "send_to": this.$store.state.siteConfig.googleConversion,
            "value": Number(this.flight.cpc).toFixed(2),
            "currency": 'USD'
          });
        }

        // Bing conversion
        if (window.uetq) {
          window.uetq.push("event", "purchase", {
            "revenue_value": Number(this.flight.cpc).toFixed(2),
            "currency": "USD"
          })
        }

      }
    },
    closeWithDeepLink () {
      analytics.track('compare-no-thanks-click', this)
      if (this.$store.state.inlineAdDeeplink) {
        window.newTab = window.open('/loading', '_blank')
        window.newTab.location.href = this.$store.state.inlineAdDeeplink
        this.$store.commit('setInlineAdDeeplink', null)
      }
      this.$emit('close')
    },
    close () {
      this.$emit('close')
    }
  }
}
